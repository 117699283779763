@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;900&display=swap');
@import './styleVars.css';

* {
  box-sizing: border-box;
  font-family: 'Nunito Sans', sans-serif;
}

.app {
  height: 100vh;
  min-height: var(--app-min-height);
  position: relative;
}

.privateRoute {
  display: grid;
  grid-template-columns: 240px auto;
  height: 100%;
}

.appContent {
  background-color: #F1F4FA;
  padding: 40px 30px;
}

h1 {
  font-size: 24px;
  font-weight: 700;
  margin: 0px;
}

table thead tr th {
  font-weight: 900 !important;
}

// text-editor placeholder
.ql-editor.ql-blank::before {
  color: #7B809A !important;
  font-size: 18px;
  font-style: normal !important;
}

.ant-popover-inner-content {
  padding: 12px;
}

.ant-popover-message-title,
.ant-picker-input > input:placeholder-shown,
.ant-popover-buttons button {
  font-size: 14px;
}

.ant-tabs-tab-active {
  border-bottom: 2px solid var(--app-primary-color);
  padding-bottom: 10px;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background-color: transparent;
}

.ant-select.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow .ant-select-selector {
  height: 32px;
  display: flex;
  align-items: center;
}

.ant-input-number.ant-input-number-in-form-item {
  width: 100%;
}

@border-radius-base: 10px;@btn-height-base: 48px;@btn-default-ghost-color: @primary-color;@btn-default-ghost-border: @primary-color;@checkbox-border-radius: 4px;@divider-orientation-margin: 0px;@font-size-base: 18px;@font-family: "Nunito Sans", sans-serif;@input-height-base: 48px;@input-placeholder-color: #7B809A;@modal-confirm-title-font-size: 14px;@modal-confirm-body-padding: 32px 32px 24px;@primary-color: #3A36DB;@popover-padding-horizontal: 0px;@table-header-color: #4F5B67;@table-header-bg: white;@table-header-cell-split-color: transparent;@table-font-size: 12px;