.spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.mainInfoArea {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.formBlock {
  background: #FFFFFF;
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px;
  position: relative;
}

.label {
  font-size: 14px;
}

.field {
  margin-bottom: 20px;
}

.fieldValue {
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  min-height: 40px;
  padding: 8px;
}

.photoList {
  column-gap: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 184px);
  row-gap: 24px;
}

.photo {
  object-fit: cover;
  height: 184px;
  width: 184px;
}
